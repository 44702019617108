import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import InfoIcon from "@mui/icons-material/Info";
import { Stack } from "@mui/system";
import SelectWrapper from "../../../../../../Components/FormComponents/Select";

const PlanningGroupsForm = ({ month, updateMonth }) => {
  const [fieldWidth] = React.useState(4);

  console.log(month.month_name, month);

  return (
    <div>
      <Formik
        initialValues={{
          id: month.id,
          calculation_type: month.calculation_type || "",
          campaign_standard_hours: month.campaign_standard_hours || 0,
          month_group_id: month.month_group_id,
          type: month.type,
          month_name: month.month_name,
          lead_no: month.lead_no || 0,
          contact_ratio: month.contact_ratio || 0,
          target_sales_per_contact_ratio:
            month.target_sales_per_contact_ratio || 0,
          target_sales_per_hour: month.target_sales_per_hour || 0,
          forecast_average_premium: month.forecast_average_premium || 0,
          ci_per_sale: month.ci_per_sale || 0,
          pricing_model: month.pricing_model || 0,
          pricing_model_type: month.pricing_model_type || 0,
          qa_rate: month.qa_rate || 0,
          business_income_per_hour_option:
            month.business_income_per_hour_option || false,
          business_income_for_hours: month.business_income_for_hours || 0,
          cost_per_sale: month.cost_per_sale || 0,
          collection_ratio: month.collection_ratio || 0,
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateMonth.mutate(values);
        }}>
        {({ values, dirty, setFieldValue }) => {
          let contacts_per_month =
            values.lead_no * (values.contact_ratio / 100);

          let target_sales =
            contacts_per_month * (values.target_sales_per_contact_ratio / 100);

          let target_sales_per_leads_ratio =
            (target_sales / values.lead_no) * 100;

          let target_hours = target_sales * values.target_sales_per_hour;

          let forecast_business_income_per_sale = values.pricing_model
            ? values.forecast_average_premium * values.pricing_model
            : values.forecast_average_premium;

          let forecast_business_income_for_hours = (values.lead_no / 10) * 120;

          let forecast_commission_income = target_sales * values.ci_per_sale;

          let qa_target = target_sales * (values.qa_rate / 100);

          return (
            <Form>
              <>
                <Grid container spacing={2}>
                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      type="float"
                      label="STANDARD CAMPAIGN HOURS PER DAY"
                      name="campaign_standard_hours"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">H:</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      type="float"
                      label="PLANNED LEADS FOR THE MONTH"
                      name="lead_no"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">No: </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      type="float"
                      size="small"
                      label="CONTACT RATIO"
                      name="contact_ratio"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      type="float"
                      size="small"
                      label="TARGET SALES PER CONTACT RATIO"
                      name="target_sales_per_contact_ratio"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Enter the target sales made per individual contacted as a %.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      type="float"
                      size="small"
                      label="TARGET SALES PER HOUR "
                      name="target_sales_per_hour"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">No:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Enter the planned sales per hour.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      type="float"
                      size="small"
                      label="FORECAST AVERAGE PREMIUM EXCL. VAT"
                      name="forecast_average_premium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Enter the forecast average premium per month per sale.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      name="ci_per_sale"
                      label="CI PER SALE"
                      type="float"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextfieldWrapper
                      size="small"
                      name="qa_rate"
                      label="QA RATE"
                      type="float"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="QA RATE">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <SelectWrapper
                      name="calculation_type"
                      size="small"
                      label="Calculation Type"
                      options={[
                        { value: "Cost per sale", label: "Cost per sale" },
                        {
                          value: "Collection Ratio Model",
                          label: "Collection Ratio Model",
                        },
                        { value: "P Factor Model", label: "P Factor Model" },
                      ]}
                    />
                  </Grid>

                  {values.calculation_type === "Cost per sale" && (
                    <>
                      <Grid item xs={fieldWidth}>
                        <TextfieldWrapper
                          size="small"
                          name="cost_per_sale"
                          label="Cost per sale"
                          type="float"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <Tooltip title="Enter the cost per sale.">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={fieldWidth}>
                        <TextField
                          disabled
                          size="small"
                          fullWidth
                          label="FORECAST THEORETICAL BUSINESS INCOME PER SALE"
                          type="float"
                          name="FORECAST THEORETICAL BUSINESS INCOME PER SALE"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R:
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <Tooltip title="FORECAST THEORETICAL BUSINESS INCOME PER SALE">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                          value={values.cost_per_sale}
                        />
                      </Grid>
                      <Grid item xs={fieldWidth}>
                        <TextField
                          disabled
                          size="small"
                          fullWidth
                          label="FORECAST THEORETICAL BUSINESS INCOME"
                          type="float"
                          name="FORECAST THEORETICAL BUSINESS INCOME"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R:
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <Tooltip title="FORECAST THEORETICAL BUSINESS INCOME = target sales x cost per sale.">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                          value={
                            target_sales && values.cost_per_sale
                              ? (target_sales * values.cost_per_sale).toFixed(2)
                              : 0
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {/* 2.	Collection Ratio Model :
•	Capture Fields
o	Pricing Model XP
o	Forecast Average Premium excl. VAT
o	Collection Ratio (this is a new field you need to add) – percentage format
•	Calculated Fields 
o	Forecast theoretical business income = (Forecast Average Premium excl. VAT * Pricing Model XP) * Target sales * Collection Ratio */}

                  {values.calculation_type === "Collection Ratio Model" && (
                    <>
                      <Grid item xs={fieldWidth}>
                        <TextfieldWrapper
                          size="small"
                          name="pricing_model"
                          label="Pricing model XP"
                          type="float"
                          InputProps={{
                            endAdornment: (
                              <Tooltip title="Enter the x P as a numeral if the pricing model is a x P model.">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={fieldWidth}>
                        <TextfieldWrapper
                          size="small"
                          name="collection_ratio"
                          label="Collection Ratio"
                          type="float"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <Tooltip title="Enter the collection ratio.">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={fieldWidth}>
                        <TextField
                          disabled
                          size="small"
                          fullWidth
                          label="FORECAST THEORETICAL BUSINESS INCOME PER SALE"
                          type="float"
                          name="FORECAST THEORETICAL BUSINESS INCOME PER SALE"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R:
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <Tooltip title="FORECAST THEORETICAL BUSINESS INCOME PER SALE">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                          value={
                            values?.forecast_average_premium &&
                            values?.pricing_model &&
                            values?.collection_ratio
                              ? (
                                  values.forecast_average_premium *
                                  values.pricing_model *
                                  (values.collection_ratio / 100)
                                ).toFixed(2)
                              : 0
                          }
                        />
                      </Grid>
                      <Grid item xs={fieldWidth}>
                        <TextField
                          disabled
                          size="small"
                          fullWidth
                          label="FORECAST THEORETICAL BUSINESS INCOME"
                          type="float"
                          name="FORECAST THEORETICAL BUSINESS INCOME"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R:
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <Tooltip title="Forecast theoretical business income = (Forecast Average Premium excl. VAT * Pricing Model XP) * Target sales * Collection Ratio">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                          value={
                            values?.forecast_average_premium &&
                            values?.pricing_model &&
                            target_sales &&
                            values?.collection_ratio
                              ? (
                                  values.forecast_average_premium *
                                  values.pricing_model *
                                  target_sales *
                                  (values.collection_ratio / 100)
                                ).toFixed(2)
                              : 0
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {/* 3.	P Factor Model :
•	Capture Fields
o	Pricing Model XP
•	Calculated Fields 
o	Forecast theoretical business income = Target sales * Pricing Model XP */}
                  {values.calculation_type === "P Factor Model" && (
                    <>
                      <Grid item xs={fieldWidth}>
                        <TextfieldWrapper
                          size="small"
                          name="pricing_model"
                          label="Pricing model XP"
                          type="float"
                          InputProps={{
                            endAdornment: (
                              <Tooltip title="Enter the x P as a numeral if the pricing model is a x P model.">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={fieldWidth}>
                        <TextField
                          disabled
                          size="small"
                          fullWidth
                          label="FORECAST THEORETICAL BUSINESS INCOME PER SALE"
                          type="float"
                          name="FORECAST THEORETICAL BUSINESS INCOME PER SALE"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R:
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <Tooltip title="FORECAST THEORETICAL BUSINESS INCOME PER SALE">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                          value={
                            forecast_business_income_per_sale
                              ? forecast_business_income_per_sale.toFixed(2)
                              : 0
                          }
                        />
                      </Grid>

                      <Grid item xs={fieldWidth}>
                        <TextField
                          disabled
                          size="small"
                          fullWidth
                          label="FORECAST THEORETICAL BUSINESS INCOME"
                          type="float"
                          name="FORECAST THEORETICAL BUSINESS INCOME"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R:
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <Tooltip title="Forecast theoretical business income = Target sales * Pricing Model XP">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                          value={
                            forecast_business_income_per_sale && target_sales
                              ? (
                                  forecast_business_income_per_sale *
                                  target_sales
                                ).toFixed(2)
                              : 0
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                {/* ///////////////////////////////////////////////// */}
                {/* ///////////////////////////////////////////////// */}
                {/* ///////////////////////////////////////////////// */}
                {/* ///////////////////////////////////////////////// */}
                <Divider sx={{ mb: 3, mt: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      label="Contacts per month"
                      type="float"
                      name="ContactsPerMonth"
                      value={contacts_per_month.toFixed(2)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">No:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Contacts per month = Lead No x Contact Ratio.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      label="TARGET SALES"
                      type="float"
                      name="ContactsPerMonth"
                      value={target_sales.toFixed(2)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">No:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Target sales = contacts per month x target sales per contact ratio.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      size="small"
                      fullWidth
                      type="float"
                      label="TARGET SALES PER LEADS RATIO"
                      disabled
                      name="TARGET SALES PER LEADS RATIO"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="(target_sales / lead_no) * 100">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={target_sales_per_leads_ratio.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      label="TARGET HOURS"
                      type="float"
                      name="TARGET HOURS"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">H:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip title="Target hours = target sales x target sales per hour.">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={target_hours.toFixed(2)}
                    />
                  </Grid>

                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      label="FORECAST COMMISSION INCOME"
                      type="float"
                      name="FORECAST COMMISSION INCOME"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R:</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip
                            title="FORECAST COMMISSION INCOME = 
                            target sales x CI per sale.
                            ">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={forecast_commission_income.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={fieldWidth}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      label="QA Target"
                      type="float"
                      name="QA Target"
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            title="FORECAST COMMISSION INCOME = 
                            target sales x CI per sale.
                            ">
                            <InfoIcon />
                          </Tooltip>
                        ),
                      }}
                      value={qa_target.toFixed(2)}
                    />
                  </Grid>

                  {values.business_income_per_hour_option && (
                    <Grid item xs={fieldWidth}>
                      <TextField
                        disabled
                        size="small"
                        fullWidth
                        label="Forecast Business Income for Hours"
                        type="float"
                        name="Forecast Business Income for Hours"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R:</InputAdornment>
                          ),
                          endAdornment: (
                            <Tooltip title="If a x P model, BI = average premium x (x P) factor. If a % API model, BI = (average premium x 12) x % API.">
                              <InfoIcon />
                            </Tooltip>
                          ),
                        }}
                        value={forecast_business_income_for_hours.toFixed(2)}
                      />
                    </Grid>
                  )}
                </Grid>
              </>

              <Stack sx={{ mt: 4 }}>
                {dirty && (
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                )}
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PlanningGroupsForm;
